/**
 * @property {HTMLElement} form
 */
import { jsonFetchForm } from '../functions/http'
import { replaceChildNodes } from '../functions/dom'

export class FormFlow extends HTMLFormElement {
  async connectedCallback () {
    this.dispatchEvent(new CustomEvent('load'))
    this.bindSubmit()
    this.bindKeyPress()
    this.initialData = new FormData(this)
    this.updateHeader()
  }

  async onSubmit (e) {
    e.preventDefault()
    const name = e.submitter.name
    const value = e.submitter.value

    const body = new FormData(e.target)
    if (name) {
      body.append(name, value)
    }
    if (
      new URLSearchParams(this.initialData).toString() ===
        new URLSearchParams(body).toString() &&
      value === 'finish' &&
      !this.getAttribute('finish')
    ) {
      this.dispatchEvent(new CustomEvent('finish'))
    } else {
      try {
        const data = await jsonFetchForm(e.target.action, {
          method: e.target._method?.value ?? e.target.method,
          body: body
        })
        if (!(value === 'finish')) {
          this.createFragment(data)
          this.initialData = new FormData(this)
          this.dispatchEvent(new CustomEvent('load'))
        } else {
          this.dispatchEvent(new CustomEvent('finish'))
        }
      } catch (data) {
        this.createFragment(data)
      }
    }
  }

  createFragment (data) {
    const fragment = document
      .createRange()
      .createContextualFragment(data)
      .querySelector('form')
    replaceChildNodes(this, fragment)

    this.scrollTop()
    this.bindModalClose()
    this.updateHeader()
  }

  onKeyPress (e) {
    if (e.code === 'Enter') {
      e.preventDefault()
      document.querySelector('[value="finish"]').click()
    }
  }

  bindSubmit () {
    this.addEventListener('submit', this.onSubmit.bind(this))
  }

  /**
   * @todo Détecter le changement du DOM du composant AjaxModal avec MutationObserver
   */
  bindModalClose () {
    document.querySelectorAll('.js-modal-close').forEach(element => {
      element.addEventListener('click', () =>
        document.querySelector('ajax-modal').removeAttribute('visible')
      )
    })
  }

  updateHeader () {
    const template = document.getElementById('template-flow')
    const headerOriginal = document.querySelector('header:not(.navbar)')
    if (headerOriginal && template) {
      const content = template.content
      const header = content.querySelector('header:not(.navbar)')

      if (header) {
        headerOriginal.replaceWith(header)
      }
    }
  }

  scrollTop () {
    if (!this.querySelector('.form__error')) {
      window.scrollTo(0, 0)
    }
  }

  bindKeyPress () {
    this.addEventListener('keypress', this.onKeyPress.bind(this))
  }

  get currentStepNumber () {
    return this.querySelector('[name*="_step"]').value
  }
}
